@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;
  background-color: #fff;
  font-family: 'Noto Sans KR', sans-Serif;
  font-weight: 400;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-Serif;
  box-sizing: border-box;

  /* 드래그방지  */
  /* -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none; */
}

a {
  text-decoration: none;
  color: inherit;
}

button,
select {
  border: none;
  background: none;
  cursor: pointer;
}

input,
textarea {
  outline-color: #4575f6;
}
